<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{$t('menu_privilege.list')}}</h4>
      </template>
      <template v-slot:body>
        <b-row>
          <b-col xs="12" sm="12" md="6">
            <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('org_pro.component')"
              label-for="component_id"
            >
            <b-form-select
              plain
              v-model="search.component_id"
              :options="componentList"
              id="component_id"
              >
              <template v-slot:first>
                <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
              </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6">
            <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('org_pro.module')"
              label-for="module_id"
            >
            <b-form-select
              plain
              v-model="search.module_id"
              :options="moduleList"
              id="module_id"
              >
              <template v-slot:first>
                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6">
            <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('org_pro.service')"
              label-for="service_id"
            >
            <b-form-select
              plain
              v-model="search.service_id"
              :options="serviceList"
              id="service_id"
              >
              <template v-slot:first>
                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6">
            <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('org_pro.menu')"
              label-for="master_menu_id"
            >
            <b-form-select
              plain
              v-model="search.master_menu_id"
              :options="menuList"
              id="master_menu_id"
              >
              <template v-slot:first>
                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6">
            <b-form-group
              class="row"
              label-cols-sm="3"
              label-for="role_id"
              :label="$t('user_role.roles')"
              >
              <b-form-select
                plain
                v-model="search.role_id"
                :options="roleList"
                >
                <template v-slot:first>
                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
          </b-col>
        </b-row>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{$t('user_role.MenuPrivilegeList')}}</h4>
          </template>
          <template v-slot:headerAction>
            <router-link class="btn btn_add_new" :to="{ name: 'common_service.access_control.menu_privilege' }">
              <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
            </router-link>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                 <b-col md="12" class="table-responsive">
                  <b-table bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(component_name)="data">
                      <span class="capitalize">{{ data.item.component_name }}</span>
                    </template>
                    <template v-slot:cell(module_name)="data">
                      <span class="capitalize">{{ data.item.module_name }}</span>
                    </template>
                    <template v-slot:cell(service_name)="data">
                      <span class="capitalize">{{ data.item.service_name }}</span>
                    </template>
                    <template v-slot:cell(menu_name)="data">
                      <span class="capitalize">{{ data.item.menu_name }}</span>
                    </template>
                    <template v-slot:cell(role)="data">
                      {{ ($i18n.locale === 'bn') ? data.item.role.role_name_bn : data.item.role.role_name }}
                    </template>
                  </b-table>
                  <b-pagination
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { mapGetters } from 'vuex'
import RestApi, { authServiceBaseUrl } from '@/config/api_config'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
  mixins: [ModalBaseMasterList],
  components: {
  },
  data () {
    return {
      search: {
        search_key: '',
        component_id: 0,
        org_id: 0,
        service_id: 0,
        module_id: 0,
        master_menu_id: 0,
        role_id: 0
      },
      testId: 0,
      rows: [],
      user_detail: '',
      datas: [],
      moduleList: [],
      serviceList: [],
      menuList: [],
      allRoles: [],
      authServiceBaseUrl: authServiceBaseUrl
    }
  },
  computed: {
    componentList: function () {
      return this.authorizedComponents.map(item => {
        return { value: item.id, text: this.$i18n.locale === 'en' ? item.component_name : item.component_name_bn }
      })
    },
    roleList: function () {
      const listObject = this.allRoles
      const tmpList = listObject.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.id, text: obj.role_name_bn }
        } else {
          return { value: obj.id, text: obj.role_name }
        }
      })
      return tmpList
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('user.role'), class: 'text-left' },
          { label: this.$t('org_pro.component_namel'), class: 'text-left' },
          { label: this.$t('org_pro.module_namel'), class: 'text-left' },
          { label: this.$t('org_pro.service_namel'), class: 'text-left' },
          { label: this.$t('org_pro.menu_namel'), class: 'text-left' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'role' },
          { key: 'component_name_bn' },
          { key: 'module_name_bn' },
          { key: 'service_name_bn' },
          { key: 'menu_name_bn' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'role' },
          { key: 'component_name' },
          { key: 'module_name' },
          { key: 'service_name' },
          { key: 'menu_name' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    },
    ...mapGetters({
      commonObj: 'commonObj',
      authorizedComponents: 'Auth/getAuthorizedComponents',
      authUser: 'Auth/authUser'
    })
  },
  watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    },
    'search.component_id': function (newVal, oldVal) {
      this.moduleList = this.getModuleList(newVal)
    },
    'search.module_id': function (newVal, oldVal) {
      this.serviceList = this.getServiceList(newVal)
      this.menuList = this.getMenuList(newVal, 'module')
    },
    'search.service_id': function (newVal, oldVal) {
      this.menuList = this.getMenuList(newVal, 'service')
    }
  },
  // created () {
  //   this.loadData()
  // },
  mounted () {
    this.rolData()
    core.index()
    if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
      this.search = Object.assign({}, this.search, {
        role_id: this.authUser.role_id
      })
    } else if (this.authUser.role_id === 0) {
      this.search = Object.assign({}, this.search, {
        role_id: this.authUser.office_detail.role_id
      })
    }
    if (this.$store.state.Auth.activeRoleId !== 1) {
      this.search.org_id = this.$store.state.Auth.authUser.org_id
    }
    this.loadData()
  },
  methods: {
    searchData () {
      if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
        this.search = Object.assign({}, this.search, {
          role_id: this.authUser.role_id
        })
      } else if (this.authUser.role_id === 0) {
        this.search = Object.assign({}, this.search, {
          role_id: this.authUser.office_detail.role_id
        })
      }
      this.loadData()
    },
    details (item) {
      this.user_detail = item
    },
    remove (item) {
      this.$swal({
        title: this.$t('globalTrans.deleteMsg'),
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        focusConfirm: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteData(item)
        }
      })
    },
    async loadData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      await RestApi.getData(authServiceBaseUrl, 'role/role-wise-menu-list', params).then(response => {
        if (response.success) {
          this.getRelationalData(response)
          this.paginationData(response.data)
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      }, error => {
        if (error) {
          //
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getRelationalData (data) {
      const componentList = this.$store.state.commonObjCommonConfig.componentList
      const moduleList = this.$store.state.commonObjCommonConfig.moduleList
      const serviceList = this.$store.state.commonObjCommonConfig.serviceList
      const menuList = this.$store.state.commonObjCommonConfig.menuList
      const allData = data.data.data.map(item => {
        const componentObject = componentList.find(component => component.value === item.component_id)
        const moduleObject = moduleList.find(module => module.value === item.module_id)
        const serviceObject = serviceList.find(service => service.value === item.service_id)
        const menuObject = menuList.find(menu => menu.value === item.master_menu_id)
        const componentData = {
          component_name: typeof componentObject !== 'undefined' ? componentObject.text_en : '',
          component_name_bn: typeof componentObject !== 'undefined' ? componentObject.text_bn : ''
        }
        const moduleData = {
          module_name: typeof moduleObject !== 'undefined' ? moduleObject.text_en : '',
          module_name_bn: typeof moduleObject !== 'undefined' ? moduleObject.text_bn : ''
        }
        const serviceData = {
          service_name: typeof serviceObject !== 'undefined' ? serviceObject.text_en : '',
          service_name_bn: typeof serviceObject !== 'undefined' ? serviceObject.text_bn : ''
        }
        const menuData = {
          menu_name: typeof menuObject !== 'undefined' ? menuObject.text_en : '',
          menu_name_bn: typeof menuObject !== 'undefined' ? menuObject.text_bn : ''
        }
        return Object.assign({}, item, componentData, moduleData, serviceData, menuData)
      })
      this.$store.dispatch('setList', allData)
    },
    getModuleList (componentId) {
      const moduleList = this.$store.state.commonObjCommonConfig.moduleList.filter(item => item.status === 0)
      if (componentId) {
          const moduleData = moduleList.filter(module => module.component_id === componentId)
          const tmpList = moduleData.map((obj, index) => {
            if (this.$i18n.locale === 'bn') {
              return { value: obj.value, text: obj.text_bn }
            } else {
              return { value: obj.value, text: obj.text }
            }
          })
          return tmpList
      }
    },
    getServiceList (moduleId) {
      const serviceList = this.$store.state.commonObjCommonConfig.serviceList.filter(item => item.status === 0)
      if (moduleId) {
        const serviceData = serviceList.filter(service => service.module_id === moduleId)
        const tmpList = serviceData.map((obj, index) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.value, text: obj.text_bn }
          } else {
            return { value: obj.value, text: obj.text_en }
          }
        })
        return tmpList
      }
    },
    getMenuList (Id, type) {
      const menuList = this.$store.state.commonObjCommonConfig.menuList
      if (Id) {
        let menuData = ''
        if (type === 'module') {
          menuData = menuList.filter(menu => menu.module_id === Id)
        } else {
          menuData = menuList.filter(menu => menu.service_id === Id)
        }
        const tmpList = menuData.map((obj, index) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.value, text: obj.text_bn }
          } else {
            return { value: obj.value, text: obj.text_en }
          }
        })
        return tmpList
      }
    },
    rolData () {
      RestApi.getData(authServiceBaseUrl, 'role/role-list-select/all', {}).then(response => {
        this.allRoles = response
      }, error => {
        if (error) {
          //
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    }
  }
}
</script>
